
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

export function AxiosInterceptor() {
axios.interceptors.request.use(
    function (config) {
        if(!shouldSkipTokenCheck(config.url)){
            if (sessionStorage.getItem('token')){
                let token=atob(sessionStorage.getItem('token')).replace("\"","");
                console.log(token)
                config.headers.Authorization = `Bearer ${token}`;
                if(isTokenExpired(token)){
                    eliminaToken()
                    return
                }
            }
            }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

}


const urlsWithoutTokenCheck = [
    '/referendum/comune/{comId}',
    '/referendum/{refId}',
    '/petizione/comune/{comId}',
    '/petizione/{petId}',
    '/bilancio-partecipativo/comune/{comId}',
    '/bilancio-partecipativo/{bilId}',
    '/partecipante/richiediToken',
    '/partecipante/login-spid',
    '/partecipante/confermaToken/{token}',
    '/comune/{id}',
    '/partecipante/login',

  ];
/*
  const urlsWithoutTokenCheck = [
    '/api/referendum/comune/{comId}',
    '/api/referendum/{refId}',
    '/api/petizione/comune/{comId}',
    '/api/petizione/{petId}',
    '/api/bilancio-partecipativo/comune/{comId}',
    '/api/bilancio-partecipativo/{bilId}',
    '/api/partecipante/richiediToken',
    '/api/partecipante/login-spid',
    '/api/partecipante/confermaToken/{token}',
    '/api/comune/{id}',
    '/api/partecipante/login'
  ];
  */

export function shouldSkipTokenCheck(url) {
    return urlsWithoutTokenCheck.some(pattern => {
      const regexPattern = new RegExp('^' + pattern.replace(/{[^}]+}/g, '[^/]+') + '$');
      return regexPattern.test(url);
    });
  }
export function isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      const exp = decoded.exp;
      if (!exp) return true; // Se non c'è la scadenza, consideriamo il token scaduto
  
      const now = Date.now() / 1000; // Tempo attuale in secondi
      return exp < now;
    } catch (e) {
      return true; // Se il token non può essere decodificato, consideriamo scaduto
    }
  }

export function getToken() {
    return  atob(sessionStorage.getItem('token').replace(/[^A-Za-z0-9+/=]/g, ''))
  }

export function eliminaToken() {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('partecipante')
    window.location.href = '/login' 
  }